import {
  mapEntityById,
  mapQueryIdentifiers,
  mutationIgnoreAttributeInstance,
  queryEntityById,
  queryIdentifiers,
  queryIgnoreBlob
} from './queries'
import service from '../../services/api/apiService'
import {
  Classification,
  Document,
  GetTextLabelsParams,
  Identifier,
  UserEntity,
  ConversationMessage,
  Email
} from '../../services/api/apiTypes'
import {
  BoundingBoxUI,
  IdentifiersInSelectedArea,
  IGetDocumentsParams,
  SORT_ORDER
} from '../../interfaces'
import {
  ACTION_TEXT_LABELS_GET,
  MESSAGE,
  DOC_TYPE_XLS,
  DOC_TYPE_DOCX,
  DOC_TYPE_IMAGE,
  DOC_TYPE_PDF,
  DOC_TYPE_UNDEFINED,
  DATA_SOURCE_TYPES,
  TEXT,
  DOC_TYPE_XLSX,
  DOC_TYPE_CSV,
  DOC_TYPE_DOC,
  DATA_SOURCE_ID
} from '../../constants'
import {
  getDocumentTypeFromMime,
  mapBoundingBoxesForUI,
  transformFileToArrayBuffer,
  transformFileToBinary,
  transformFileToDataUrl
} from '../../utils/documentUtil'
import { sortByOrder } from '../../utils/sortUtil'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface ConversationFiles {
  documentId: string
  fileSource: string | ArrayBuffer | null
  type?: string
}

interface DocumentState {
  selectedDocument?: Document
  fileSource: string | null | ArrayBuffer
  fileType?: string
  entities: UserEntity[]
  categories: Classification[]
  identifiers: Identifier[]
  identifiersInSelectedArea: IdentifiersInSelectedArea
  editedBoxes: BoundingBoxUI[]
  selectedContext: string | null
  readonly: boolean
  templateFiles: Array<string | null | ArrayBuffer> | null
  conversation?: ConversationMessage[]
  conversationMessageDocument?: Document
  conversationAttachedDocuments?: Document[]
  conversationAttachedFiles?: ConversationFiles[]
  selectedConversationDocumentId?: string
  emailInfo?: Email
  isTemplatePublished: boolean
  refreshDocument?: boolean
}

export const initialState: DocumentState = {
  fileSource: null,
  entities: [],
  categories: [],
  editedBoxes: [],
  selectedContext: null,
  identifiers: [],
  identifiersInSelectedArea: {
    result: null,
    isPending: false,
    coordinates: {
      leftUpperX1: 0,
      leftUpperY1: 0,
      rightLowerX2: 0,
      rightLowerY2: 0
    },
    documentId: '',
    pageNumber: 1
  },
  readonly: false,
  templateFiles: null,
  isTemplatePublished: false
}

const datasourceDocumentTypesMapper = {
  [DATA_SOURCE_TYPES.jira]: TEXT
}

export const ACTION_FETCH_DOCUMENT_BY_ID = 'document/get'
export const fetchDocumentById = createAsyncThunk(
  ACTION_FETCH_DOCUMENT_BY_ID,
  async (documentId: string): Promise<Document> => {
    const document = await service.getDocumentById(documentId)
    if (document.boundingBoxTags) {
      document.boundingBoxTags = mapBoundingBoxesForUI(document)
    }

    return document
  }
)
export const fetchDocumentByFilters = createAsyncThunk(
  'document/filter',
  async (filters: IGetDocumentsParams) => {
    const documents = await service.getDocuments(filters)
    return documents.list.map((document) => {
      if (document.boundingBoxTags) {
        document.boundingBoxTags = mapBoundingBoxesForUI(document)
      }
      return document
    })
  }
)

export const ACTION_ANNOTATIONS_FILE = 'document/file'
export const fetchFileByDocumentId = createAsyncThunk(
  ACTION_ANNOTATIONS_FILE,
  async (params: { documentId: string; date?: string }, { rejectWithValue }) => {
    try {
      const rawFile = await service.getFileByDocumentId(params.documentId, params?.date)
      const type = getDocumentTypeFromMime(rawFile.type) || ''
      let fileSource: DocumentState['fileSource'] = ''

      if (type === DOC_TYPE_CSV || type === DOC_TYPE_XLS || type === DOC_TYPE_XLSX) {
        fileSource = await transformFileToBinary(rawFile)
      } else if (type === DOC_TYPE_DOC || type === DOC_TYPE_DOCX) {
        fileSource = await transformFileToArrayBuffer(rawFile)
      } else {
        fileSource = await transformFileToDataUrl(rawFile)
      }
      return { documentId: params.documentId, fileSource, type }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const ACTION_PUBLISH_DOCUMENT = 'document/classified/post'
export const publishClassifiedDocument = createAsyncThunk(
  ACTION_PUBLISH_DOCUMENT,
  async (document: Document, { rejectWithValue }) => {
    try {
      await service.putDocumentById(document)

      return { statusMessage: 'annotations.publish.message.success', document }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'annotations.publish.message.error' })
    }
  }
)

export const fetchDocumentEntities = createAsyncThunk(
  'document/entities/get',
  async (entitieIds: string[]) => {
    const rawResult = await Promise.allSettled(
      entitieIds.map((id) => graphqlService.execute(queryEntityById(id)))
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<
      any
    >[]

    return results.map((res) => mapEntityById(res.value))
  }
)

export const fetchCategories = createAsyncThunk(
  'document/categories/get',
  async () => await service.getClassificationsList()
)

export const ACTION_FETCH_ANNOTATOR_IDENTIFIERS = 'document/identifiers'
export const fetchIdentifiers = createAsyncThunk(ACTION_FETCH_ANNOTATOR_IDENTIFIERS, async () => {
  const raw = await graphqlService.execute(queryIdentifiers())
  return await mapQueryIdentifiers(raw)
})

export const fetchTextLabels = createAsyncThunk(
  ACTION_TEXT_LABELS_GET,
  async (params: GetTextLabelsParams) => {
    const result = await service.postTextLabels(params)

    return { ...params, result }
  }
)

export const fetchConversationMessages = createAsyncThunk(
  'document/getConversations',
  async (documentId: string) => await service.getConversations(documentId)
)

export const fetchConversationAttachedFiles = createAsyncThunk(
  'document/getConversationAttachedFiles',
  async (documentIds: string[]) => {
    const rawResult = await Promise.allSettled(
      documentIds.map(async (documentId) => {
        const rawFile = await service.getFileByDocumentId(documentId)
        const type = getDocumentTypeFromMime(rawFile.type) || ''

        let fileSource: DocumentState['fileSource'] = ''

        if (type === DOC_TYPE_CSV || type === DOC_TYPE_XLS || type === DOC_TYPE_XLSX) {
          fileSource = await transformFileToBinary(rawFile)
        } else if (type === DOC_TYPE_DOC || type === DOC_TYPE_DOCX) {
          fileSource = await transformFileToArrayBuffer(rawFile)
        } else {
          fileSource = await transformFileToDataUrl(rawFile)
        }

        return { documentId, fileSource, type }
      })
    )

    const results = rawResult.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<
      any
    >[]

    return results.map((res) => res.value)
  }
)

export const fetchEmailInfo = createAsyncThunk(
  'document/email/get',
  async (mailId: string) => await service.getEmailById(mailId)
)

export type IgnoreObjectProps = {
  [DATA_SOURCE_ID]: string
  key: string
  value: string[]
}
export const ACTION_IGNORE_BLOB = 'document/ignoreBlob'
export const ignoreBlob = createAsyncThunk(
  ACTION_IGNORE_BLOB,
  async (params: IgnoreObjectProps) => {
    await graphqlService.execute(queryIgnoreBlob(params))
  }
)

export const ACTION_IGNORE_ATTRIBUTE_INSTANCE = 'document/ignoreAttributeInstance'
export const ignoreAttributeInstance = createAsyncThunk(
  ACTION_IGNORE_ATTRIBUTE_INSTANCE,
  async (attributeInstanceId: string) => {
    await graphqlService.execute(mutationIgnoreAttributeInstance(attributeInstanceId))
  }
)

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setEditedBoxes: (state, { payload }) => {
      state.editedBoxes = payload
    },
    setFileSource: (state, { payload }) => {
      state.fileSource = payload
    },
    updateEditedBoxes: (state, { payload }) => {
      state.editedBoxes = state.editedBoxes.map((box) => {
        const updatedBox = payload.find(({ localId }) => localId === box.localId)
        return updatedBox ? { ...box, ...updatedBox } : box
      })
    },
    setSelectedContext: (state, { payload }) => {
      state.selectedContext = payload
    },
    setReadonly: (state, action) => {
      state.readonly = action.payload
    },
    setSelectedDocument: (state, action) => {
      state.selectedDocument = action.payload
    },
    clearIdentifiersInSelectedArea: (state) => {
      state.identifiersInSelectedArea = initialState.identifiersInSelectedArea
    },
    setRefreshDocument: (state, { payload }) => {
      state.refreshDocument = payload
    },
    resetDocument: () => initialState,
    resetDocumentEntities: (state) => {
      state.entities = initialState.entities
    },
    resetFile: (state) => {
      state.fileSource = initialState.fileSource
      state.fileType = initialState.fileType
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentById.fulfilled, (state, { payload: document }) => {
      state.selectedDocument = document
    })
    builder.addCase(fetchConversationMessages.fulfilled, (state, action) => {
      state.conversation = action.payload
    })
    builder.addCase(fetchDocumentByFilters.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { ticketId }
        },
        payload
      } = action
      const datasourceType = ticketId ? DATA_SOURCE_TYPES.jira : null
      const list = payload
      const docType = datasourceType ? datasourceDocumentTypesMapper[datasourceType] : MESSAGE

      const messageDoc = list.find(({ type }) => type === docType)

      if (messageDoc) {
        state.conversationMessageDocument = messageDoc
      }

      const attachedDocs = list.filter(({ type }) => type !== docType)

      if (attachedDocs.length > 0) {
        state.conversationAttachedDocuments = attachedDocs
      }

      if (list.length === 1) {
        state.selectedDocument = list[0]
      }
    })
    builder.addCase(fetchFileByDocumentId.fulfilled, (state, { payload }: { payload: any }) => {
      if (payload?.fileSource && payload?.type) {
        state.fileSource = payload.fileSource
        state.fileType = payload.type

        // BE limitations
        if (
          payload.type !== DOC_TYPE_IMAGE &&
          payload.type !== DOC_TYPE_PDF &&
          payload.type !== DOC_TYPE_DOC &&
          payload.type !== DOC_TYPE_DOCX
        ) {
          state.readonly = true
        }
      }
    })

    builder.addCase(fetchConversationAttachedFiles.fulfilled, (state, { payload }) => {
      state.conversationAttachedFiles = state.conversationAttachedDocuments?.map((doc) => {
        const file = payload.find((file) => file.documentId === doc.id)
        return file || { documentId: doc.id, fileSource: '', type: DOC_TYPE_UNDEFINED }
      })
    })
    builder.addCase(fetchDocumentEntities.fulfilled, (state, action) => {
      state.entities = action.payload
    })
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload
    })
    builder.addCase(fetchIdentifiers.fulfilled, (state, { payload }) => {
      state.identifiers = sortByOrder(payload, 'name', SORT_ORDER.ASC)
    })
    builder.addCase(fetchTextLabels.pending, (state) => {
      state.identifiersInSelectedArea = {
        ...initialState.identifiersInSelectedArea,
        isPending: true
      }
    })
    builder.addCase(fetchTextLabels.fulfilled, (state, { payload }) => {
      state.identifiersInSelectedArea = {
        result: payload.result,
        coordinates: payload.boundingBox,
        documentId: payload.documentId,
        pageNumber: payload.pageNumber || 1,
        isPending: false
      }
    })
    builder.addCase(fetchTextLabels.rejected, (state) => {
      state.identifiersInSelectedArea = {
        ...initialState.identifiersInSelectedArea,
        isPending: false
      }
    })
    builder.addCase(fetchEmailInfo.fulfilled, (state, action) => {
      state.emailInfo = action.payload
    })
    // TODO add right types for payload later
    builder.addCase(publishClassifiedDocument.fulfilled, (state, { payload }: { payload: any }) => {
      state.isTemplatePublished = true

      if (state.selectedDocument) {
        state.selectedDocument.class = payload.document.class
        state.selectedDocument.subclass = payload.document.subclass
      }
    })
  }
})

export const {
  setEditedBoxes,
  updateEditedBoxes,
  setFileSource,
  setSelectedContext,
  setReadonly,
  setSelectedDocument,
  clearIdentifiersInSelectedArea,
  resetDocument,
  resetDocumentEntities,
  resetFile,
  setRefreshDocument
} = documentSlice.actions

export default documentSlice.reducer
